import React, { useEffect, useRef } from "react";
import "./Saarthi.css";
import Markdown from "react-markdown";

const SaarthiInternal = ({ setChange, messages, setMessages, loading }) => {
  const chatBoxRef = useRef(null);

  useEffect(() => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTo({
        top: chatBoxRef.current.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [messages]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <section class="slider-wrap">
        <div class="ques-ans-block" ref={chatBoxRef}>
          {messages.length > 0 &&
            messages.map((message, index) => {
              return (
                <>
                  {message.sender === "user" ? (
                    <div class="question-block">{message.text}</div>
                  ) : (
                    <div
                      class={
                        index === messages.length - 1
                          ? "answer-block"
                          : "answer-block mb-5"
                      }
                    >
                      <Markdown>{message.text}</Markdown>
                    </div>
                  )}
                </>
              );
            })}
          {loading && (
            <div class="skeleton-container mt-1 mb-3">
              <div class="skeleton-icon"></div>
              <div class="skeleton-text">
                <div class="skeleton-line"></div>
                <div class="skeleton-line"></div>
              </div>
            </div>
          )}
        </div>
      </section>
    </div>
  );
};

export default SaarthiInternal;
