import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import SuccessPopup from "./SuccessPopup";
import "./forgotPassword.css";
import axios from "axios";

const ForgotPassword = () => {
  const location = useLocation();
  const navigation = useNavigate();
  const { requestEmail } = location.state || {};

  const [success, setSuccess] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  useEffect(() => {
    if (!requestEmail) {
      navigation("/forget-password-email-verify");
    }
  }, []);

  const resetPassword = async () => {
    if (!passwordConfirm || !password) {
      setErrorMessage("Fields can not be empty");
      return;
    }

    if (password !== passwordConfirm) {
      setErrorMessage("Passwords is not matching");
      return;
    }

    const params = {
      email: requestEmail,
      password: password,
    };

    try {
      const response = await axios.post(
        `${process.env.REACT_APP_PDF_SERVER_URL}/update-password`,
        params
      );
      if (response.data.code === 200) {
        setSuccess(true);
      } else {
        setErrorMessage(response.data.data);
      }
    } catch (e) {
      setErrorMessage(e);
    }
  };

  return (
    <div
      className="forgot-password-flex"
    >
      {success ? (
        <SuccessPopup />
      ) : (
        <div className="forgot-password-container mt-3">
          <div className="forgot-password-div">
            <div className="forgot-password-title">Create New Password</div>
            <div className="forgot-password-text mt-2">
              Your new password must be different from previous used passwords.
            </div>
            <div className="forgot-password-pass mt-3">
              <input
                type="password"
                placeholder="New Password"
                onChange={(e) => {
                  setPassword(e.target.value);
                  setErrorMessage("");
                }}
                value={password}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="forgot-password-confirm-pass mt-3">
              <input
                type="password"
                placeholder="Confirm Password"
                onChange={(e) => {
                  setPasswordConfirm(e.target.value);
                  setErrorMessage("");
                }}
                value={passwordConfirm}
                style={{
                  backgroundColor: "#fff",
                }}
              />
            </div>
            <div className="forgot-password-btn mt-4">
              <div className="error-msg-div">{errMessage}</div>
              <button onClick={() => resetPassword()}>Reset Password</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default ForgotPassword;
