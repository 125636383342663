import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

import "./VerifyEmailForPasswordReset.css";
import axios from "axios";

const VerifyEmailForPasswordReset = () => {
  const navigate = useNavigate();

  const [requestEmail, setRequestEmail] = useState("");
  const [errMessage, setErrorMessage] = useState("");

  const verifyUser = async () => {
    const params = {
      email: requestEmail,
    };
    const res = await axios.post(
      `${process.env.REACT_APP_PDF_SERVER_URL}/verify-user-email`,
      params
    );

    if (res.data === true) {
      navigate("/forget-password-reset", {
        state: { requestEmail: requestEmail },
      });
    } else {
      setErrorMessage("User not found.");
    }
  };

  return (
    <div className="verify-email-flex">
      <div className="verify-email-container">
        <div className="verify-email-div">
          <div className="verify-email-title">Verify Your Email</div>
          <div className="verify-email-text mt-1">
            Please provide your email address to reset password
          </div>
          <div className="verify-email mt-3">
            <input
              type="email"
              placeholder="Email"
              value={requestEmail}
              onChange={(e) => {
                setRequestEmail(e.target.value);
                setErrorMessage("");
              }}
              style={{
                backgroundColor: "#fff",
              }}
            />
          </div>
          <div className="verify-email-btn mt-4">
            <div className="error-msg-div">{errMessage}</div>
            <button
              onClick={() => {
                requestEmail
                  ? verifyUser()
                  : setErrorMessage("Field can not be empty");
              }}
            >
              Proceed
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VerifyEmailForPasswordReset;
