import React from "react";

import successIcon from "../../assets/images/Vector.png";
import "./SuccessPopup.css";

const Success_popup = () => {
  return (
    <div>
      <div className="success_popup-container">
        <div className="success_popup-div">
          <div className="success_popup-icon">
            <img src={successIcon} alt="Success Icon" />
          </div>
          <div className="success_popup-title mt-2">Password Changed!</div>
          <div className="success_popup-text mt-2">
            Your password has been changed
          </div>
          <div className="success_popup-text">successfully.</div>
          <div className="success_popup-btn mt-4">
            <button
              onClick={() => {
                window.location.href = '/login'
              }}
            >
              Login Again
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Success_popup;
