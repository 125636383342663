import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Navbar from "./components/Navbar";
import Login from "./components/Login";
import NotFound from "./components/NotFound";
import Saarthi from "./components/saarthi/Saarthi.jsx";
import ForgotPassword from "./components/forgot_password/ForgotPassword.jsx";
import VerifyEmailForPasswordReset from "./components/forgot_password/VerifyEmailForPasswordReset.jsx";
import { exclude_url } from "./utils/saarthi_constant.js";

import "./App.css";

const App = () => {
  const [isLoggedIn, setIsLoggedIn] = useState(false);

  const checkIfLoggedIn = () => {
    const user = sessionStorage.getItem("user");
    if (user) {
      setIsLoggedIn(true);
    } else {
      setIsLoggedIn(false);
    }
  };

  const user = sessionStorage.getItem("user");

  useEffect(() => {
    checkIfLoggedIn();
  }, []);

  if (!isLoggedIn && !user && !exclude_url.includes(window.location.pathname))
    return (
      <>
        <Login setIsLoggedIn={setIsLoggedIn} />
      </>
    );

  return (
    <Router>
      <Navbar isLoggedIn={isLoggedIn} setIsLoggedIn={setIsLoggedIn} />
      <Routes>
        <Route path="/" element={<Saarthi gpt_4o_mini={true} />} />
        <Route
          path="/login"
          element={<Login setIsLoggedIn={setIsLoggedIn} />}
        />
        <Route
          path="/forget-password-email-verify"
          element={<VerifyEmailForPasswordReset />}
        />
        <Route path="/forget-password-reset" element={<ForgotPassword />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
};

export default App;
